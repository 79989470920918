import "bulma/css/bulma.min.css";

import Calculator from './calcultator';

function App() {
  return (
    <div className="main">
        <div className="box">
          <center><h1 className="title">Points Calculator</h1></center>
        </div>
      <Calculator/>
    </div>
  );
}

export default App;
